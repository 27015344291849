<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
             <SideMenu :indexsec="4"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
            <form
              @submit.prevent="
                submit(
                  name_form,
                  price_form,
                  code_form,
                  file1,
                  descrp_form,
                  category_form
                )
              "
            >
            <div class="div_EProduct">
              <p>AGREGAR PRODUCTO</p>
              </div>



              <div class="div_EPForm">
              <div class="row mx-0 row-one">
                <div class="EPF1_C1">
                  <label class="labelN color-1" for="inputN">Nombre</label>
                  <b-form-input
                  
                    v-model="name_form"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>
                <div class="EPF1_C2">
                  <label class="labelDes color-1" for="inputDes">Descripción</label>
                  <b-form-input
                  
                    v-model="descrp_form"
                    required
                    id="inputDes"
                    type="text"
                  />
                </div>
              </div>

                <div class="row mx-0 row-two mtrow">
                <div class="EPF2_C1">
                  <label class="labelNum color-1" for="inputNum">Precio</label>
                  <b-form-input
                  
                    v-model="price_form"
                    required
                    id="inputNum"
                     type="number"
                    step="any"
                  />
                </div>
                <div class="">
                  <label class="labelCod color-1" for="inputCod">Código</label>
                  <b-form-input
                  
                    v-model="code_form"
                    required
                    id="inputCod"
                    type="text"
                    pattern="\d*"
                  />
                </div>
                 <div class="">
                    <label class="labelR color-1" for="inputR">CATEGORÍA PRINCIPAL</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{category_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.categories" :key="key">
                          <b-dropdown-item @click="changeCategory(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
              
              </div>
              
                 <div class="div-image mtrow">
                  <label class="labelImg color-1" for="inputImg">Imagen</label>
                  <b-form-file
                    v-model="file1"
                    id="inputImg"
                 
                    type="file"
                    name="inputImg"
                    :placeholder="'Subir imagen'"
                  />
                </div> 


               <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>
              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">El producto se agrego correctamente</p>
                  </div>
                  <div class="">
                  
                     <div class="btns-dflx">
                            <b-button class="btn-modal" @click="editarOtro()" >AÑADIR OTRO</b-button >
                      <b-button class="btn-modal"  @click="toEdit()">EDITAR</b-button>
                                        
                      <b-button class="btn-modal" @click="toPage()" >VER PRODUCTO</b-button >
                        </div>


                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</template>
<script>

import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import categorias from '../../assets/categorias.png';
import usuarios from '../../assets/usuarios.png';
import productos from '../../assets/productos.png';
import Header from '../../components/Header';
import { mapActions } from "vuex";
export default {
  name: "AddProduct",
    components: {
    Header,
    SideMenu,
  },
  data() {
    return {
      name_form: "", 
      descrp_form: "", 
      category_form:"",
      category_name:"Categoría",
      price_form:"",
      code_form:"",
      status: "",
      msg: "",
      file1:null,
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,
      added:'',
       
      categorias: categorias,
      usuarios: usuarios,
      productos: productos,
      //pattern="[a-zA-Z 0-9]{2,254}{/()+*-_}"
    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created(){
    this.getCategories('Categorías')
  },
  computed:{
    categories() {
       let allctg =this.$store.getters["categories/data"];
       let result =[]
       let model;
       for(var i =0; i<allctg.length; i++){
         if(allctg[i].parent_name != 'BLOG' && allctg[i].name != 'BLOG'){ //Mostrar solo categorias para ropa.
           if(allctg[i].id== 1){
             model ={
                        id: allctg[i].id,
                        id_parent: allctg[i].id_parent,
                        name: 'NINGUNA',
                        parent_name: allctg[i].parent_name
                      }
          }else{
            model ={
            id: allctg[i].id,
            id_parent: allctg[i].id_parent,
            name: allctg[i].name,
            parent_name: allctg[i].parent_name
            }
          }

           result.push(model)
         }
          
         
       }

      return result
    },
        user() {            
            return this.$store.getters['admin/getIdentity'];
        },
  },

  methods: {
  ...mapActions('categories', ['getCategories']),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
     toEdit: function () {
       this.$router.push("/editar/producto/"+this.added.id).catch((err) => {});
    },

    toPage: function () {
       this.$router.push("/producto/"+this.added.url).catch((err) => {});
    },
    filterTitle: function(title) {
      let result = title.replaceAll(" ", "-").replaceAll('/','_');
      return result;
    },

    submit: async function (  name, price, code,image, descrp, id_category) {
        code = String(code)
        price = String(price)
        id_category = String(id_category)
      
      /*if(image == null){
         this.status='error'
         this.msg='Imagen no subida'
         
      }else */
      
      if(id_category == '' || id_category == undefined || id_category==null){
              this.status='error'
              this.msg='Elige una categoría.'
      }else if(image == null){

          let data ={
            'image': image,
            'name': name,
            'price': price,
            'code': code,
            'id_category': id_category,
            'descrp':descrp
          }
          this.status=''
          this.msg=''
       
          let result = await this.$store.dispatch("main/addItem",  {option:'product', item: data});
        
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
          }else{ // success
          this.added = result.added
              this.showModal() 
          }

      }else{

           if(image.size > 1000000){
                this.status='error'
                this.msg= 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
               
                
            }else{
                      var data = new  FormData();
              //Añadimos la imagen seleccionada
              data.append('image', image);
              data.append('name', name);
              data.append('price', price);
              data.append('code', code);
              data.append('id_category', id_category);
              data.append('descrp', descrp);
          
            
              data.append('_method', 'POST');
              //Enviamos la petición
            
                  
              this.status=''
              this.msg=''
          
              let result = await this.$store.dispatch("main/addItem",  {option:'product', item: data});
            
              if(result.status=='error'){
                this.status='error'
                this.msg= result.message
              }else{ // success
              this.added = result.added
                  this.showModal() 
              }
            }

   
         
      }
     
       
         
     
     
    },
    editarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    showModal(){
       this.$refs['modal-editar'].show()
    },
     changeCategory: function (value, name) {
     
       this.category_form = value;
      this.category_name = name;

    },
    
  },
};
</script>


